import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function (location) {

    return routerPush.call(this, location).catch(err => {})

};
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name:'home'
    },
    component: ()=> import('../components/monitoring/navigation.vue'),
    children:[
      {
        path: '/home',
        name: 'home',
        
        component: () => import(/* webpackChunkName: "about" */ '../components/home/home.vue'),
        meta:{
          title:'首页',
          content:{
            keywords:'上海泽兆电子科技有限公司成立于2006年；是一家专注于为国内电子企业提供相应器件,并集方案设计,技术支持,销售服务为一体的方案应用型元器件代理商企业。',
            description:'上海泽兆电子科技有限公司成立于2006年；是一家专注于为国内电子企业提供相应器件,并集方案设计,技术支持,销售服务为一体的方案应用型元器件代理商企业。'
          }
        }
      },
      {
        path:'/about',
        redirect: {
          name:'introduction'
        },
        component:()=>import('../components/about/about'),
        meta:{
          title:'企业文化',
          content:{
            keywords:'泽兆科技,zeztek',
            description:'上海泽兆是一家专注于工业马达控制及物联网安全应用领域的综合解决方案商,同时是小华半导体，英飞凌，上海贝岭等国内外知名半导体厂家的授权代理商。'
          }
        },
        children:[
          {
            path:'/culture',
            name:'culture',
            component:()=>import('../components/about/culture'),
            meta:{
              title:'企业文化',
              content:{
                keywords:'泽兆科技,zeztek',
                description:'上海泽兆是一家专注于工业马达控制及物联网安全应用领域的综合解决方案商,同时是小华半导体，英飞凌，上海贝岭等国内外知名半导体厂家的授权代理商。'
              }
            }
          },
          {
            path:'/introduction',
            name:'introduction',
            component:()=>import('../components/about/introduction'),
            meta:{
              title:'关于泽兆',
              content:{
                keywords:'泽兆科技,zeztek',
                description:'上海泽兆是一家专注于工业马达控制及物联网安全应用领域的综合解决方案商,同时是小华半导体，英飞凌，上海贝岭等国内外知名半导体厂家的授权代理商。'
              }
            }
          },
          {
            path:'/mechanism',
            name:'mechanism',
            component:()=>import('../components/about/mechanism'),
            meta:{
              title:'企业文化',
              content:{
                keywords:'泽兆科技,zeztek',
                description:'上海泽兆是一家专注于工业马达控制及物联网安全应用领域的综合解决方案商,同时是小华半导体，英飞凌，上海贝岭等国内外知名半导体厂家的授权代理商。'
              }
            }
          },
          {
            path:'/cooperation',
            name:'cooperation',
            component:()=>import('../components/about/cooperation'),
            meta:{
              title:'代理品牌',
              content:{
                keywords:'泽兆科技,zeztek',
                description:'上海泽兆是一家专注于工业马达控制及物联网安全应用领域的综合解决方案商,同时是小华半导体，英飞凌，上海贝岭等国内外知名半导体厂家的授权代理商。'
              }
            }
          },
          {
            path:'/history',
            name:'history',
            component:()=>import('../components/about/history'),
            meta:{
              title:'企业历程',
              content:{
                keywords:'泽兆科技,zeztek',
                description:'上海泽兆是一家专注于工业马达控制及物联网安全应用领域的综合解决方案商,同时是小华半导体，英飞凌，上海贝岭等国内外知名半导体厂家的授权代理商。'
              }
            }
          }
        ]
      },
      {
        path:'/news',
        redirect: {
          name:'company'
        },
        component:()=>import('../components/news/news'),
        meta:{
          title:'新闻详情',
          content:{
            keywords:'新闻中心',
            description:'新闻中心'
          }
        },
        children:[
          {
            path:'/company',
            name:'company',
            component:()=>import('../components/news/company'),
            meta:{
              title:'公司新闻',
              content:{
                keywords:'新闻中心',
                description:'新闻中心'
              }
            }
          },
          {
            path:'/industry',
            name:'industry',
            component:()=>import('../components/news/industry'),
            meta:{
              title:'行业新闻',
              content:{
                keywords:'新闻中心',
                description:'新闻中心'
              }
            }
          },
          {
            path:'/newIndex',
            name:'newIndex',
            component:()=>import('../components/news/newIndex'),
            meta:{
              title:'新闻详情',
              content:{
                keywords:'新闻中心',
                description:'新闻中心'
              }
            }
          }
        ]
      },
      {
        path:'/search',
        name:'search',
        component:() =>import('../components/search/searchIndex')
      },
      {
        path:'/service',
        redirect: {
          name:'kit'
        },
        component:()=>import('../components/service/service'),
        meta:{
          title:'技术专题',
          content:{
            keywords:'服务中心',
            description:'服务中心'
          }
        },
        children:[
          {
            path:'/kit',
            name:'kit',
            component:()=>import('../components/service/kit'),
            meta:{
              title:'开发套件',
              content:{
                keywords:'服务中心',
                description:'服务中心'
              }
            }
          },
          {
            path:'/value-added',
            name:'value-added',
            component:()=>import('../components/service/value-added'),
            meta:{
              title:'增值服务',
              content:{
                keywords:'服务中心',
                description:'服务中心'
              }
            }
          },
          {
            path:'/Specialtopic',
            name:'Specialtopic',
            component:()=>import('../components/service/Specialtopic'),
            meta:{
              title:'技术专题',
              content:{
                keywords:'服务中心',
                description:'服务中心'
              }
            }
          },
          {
            path:'/download',
            name:'download',
            component:()=>import('../components/service/download'),
            meta:{
              title:'下载中心',
              content:{
                keywords:'服务中心',
                description:'服务中心'
              }
            }
          },
          {
            path:'/LeaveMessage',
            name:'LeaveMessage',
            component:()=>import('../components/service/LeaveMessage'),
            meta:{
              title:'在线留言',
              content:{
                keywords:'服务中心',
                description:'服务中心'
              }
            }
          },
          {
            path:'/serviceIndex',
            name:'serviceIndex',
            component:()=>import('../components/service/serviceIndex'),
            meta:{
              title:'技术专题',
              content:{
                keywords:'服务中心',
                description:'服务中心'
              }
            }
          },
        ]
      },
      {
        path:'/contact',
        redirect: {
          name:'mode'
        },
        component:()=>import('../components/contact/contact'),
        meta:{
          title:'人才招聘',
          content:{
            keywords:'联系我们',
            description:'联系我们'
          }
        },
        children:[
          {
            path:'/mode',
            name:'mode',
            component:()=>import('../components/contact/mode'),
            meta:{
              title:'联系方式',
              content:{
                keywords:'联系我们',
                description:'联系我们'
              }
            }
          },
          {
            path:'/recruitment',
            name:'recruitment',
            component:()=>import('../components/contact/recruitment'),
            meta:{
              title:'人才招聘',
              content:{
                keywords:'联系我们',
                description:'联系我们'
              }
            }
          }
        ]
      },
      {
        path:'/solve',
        redirect:{
          name:'motor'
        },
        component:()=>import('../components/solve/solve'),
        meta:{
          title:'解决方案',
          content:{
            keywords:'BLDC,PMSM电机控制解决方案设计,电池供电设备超低功耗MCU应用设计,家电控制板,安防,仪表等解决方案',
            description:'电机控制解决方案基于专用电机控制芯片配套高性能功率器件/栅极驱动器/运放/电源管理等提供完整的产品方案,'
          }
        },
        children:[
          {
            name:'motor',
            path:'/motor',
            component:()=>import('../components/solve/motor'),
            meta:{
              title:'解决方案',
              content:{
                keywords:'BLDC,PMSM电机控制解决方案设计,电池供电设备超低功耗MCU应用设计,家电控制板,安防,仪表等解决方案',
                description:'电机控制解决方案基于专用电机控制芯片配套高性能功率器件/栅极驱动器/运放/电源管理等提供完整的产品方案,'
              }
            }
          },
          {
            name:'intelligence',
            path:'/intelligence',
            component:()=>import('../components/solve/intelligence')
          },
          {
            name:'tool',
            path:'/tool',
            component:()=>import('../components/solve/tool.vue')
          },
          {
            name:'other',
            path:'/other',
            component:()=>import('../components/solve/other.vue')
          },
          {
            name:'solveIndex',
            path:'/solveIndex',
            component:()=>import('../components/solve/solveIndex.vue'),
            meta:{
              title:'解决方案',
              content:{
                keywords:'上海泽兆电子科技有限公司成立于2006年；是一家专注于为国内电子企业提供相应器件,并集方案设计,技术支持,销售服务为一体的方案应用型元器件代理商企业。',
                description:'上海泽兆电子科技有限公司成立于2006年；是一家专注于为国内电子企业提供相应器件,并集方案设计,技术支持,销售服务为一体的方案应用型元器件代理商企业。'
              }
            }
          },
        ]
      },
      {
        path:'/product',
        redirect:{
          name:'controller'
        },
        component:()=>import('../components/product/product.vue'),
        meta:{
          title:'微控制器',
          content:{
            keywords:'MCU芯片,电源管理,MOSFET,EEPROM,IPM,IGBT,栅极驱动器,通讯接口芯片',
            description:'泽兆科技主要代理的产品有小华半导体MCU,英飞凌工业XMC系列MCU,Qorvo智能电机控制SOC芯片,上海贝岭的LDO,eeprom,高压MOS，IGBT,电机栅极驱动器以及其他功能各种封装芯片。'
          }
        },
        children:[
          {
            path:'/controller',
            name:'controller',
            component:()=>import('../components/product/controller.vue'),
            meta:{
              title:'微控制器',
              content:{
                keywords:'MCU芯片,电源管理,MOSFET,EEPROM,IPM,IGBT,栅极驱动器,通讯接口芯片',
                description:'泽兆科技主要代理的产品有小华半导体MCU,英飞凌工业XMC系列MCU,Qorvo智能电机控制SOC芯片,上海贝岭的LDO,eeprom,高压MOS，IGBT,电机栅极驱动器以及其他功能各种封装芯片。'
              }
            }
          },
          {
            path:'/controllerIndex',
            name:'controllerIndex',
            component:()=>import('../components/product/controllerIndex.vue'),
            meta:{
              title:'微控制器',
              content:{
                keywords:'MCU芯片,电源管理,MOSFET,EEPROM,IPM,IGBT,栅极驱动器,通讯接口芯片',
                description:'泽兆科技主要代理的产品有小华半导体MCU,英飞凌工业XMC系列MCU,Qorvo智能电机控制SOC芯片,上海贝岭的LDO,eeprom,高压MOS，IGBT,电机栅极驱动器以及其他功能各种封装芯片。'
              }
            }
          },
          {
            path:'/peripheral',
            name:'peripheral',
            component:()=>import('../components/product/peripheral.vue'),
            meta:{
              title:'电源与外围',
              content:{
                keywords:'MCU芯片,电源管理,MOSFET,EEPROM,IPM,IGBT,栅极驱动器,通讯接口芯片',
                description:'泽兆科技主要代理的产品有小华半导体MCU,英飞凌工业XMC系列MCU,Qorvo智能电机控制SOC芯片,上海贝岭的LDO,eeprom,高压MOS，IGBT,电机栅极驱动器以及其他功能各种封装芯片。'
              }
            }
          },
          {
            path:'/peripheralIndex',
            name:'peripheralIndex',
            component:()=>import('../components/product/peripheralIndex.vue'),
            meta:{
              title:'电源与外围',
              content:{
                keywords:'MCU芯片,电源管理,MOSFET,EEPROM,IPM,IGBT,栅极驱动器,通讯接口芯片',
                description:'泽兆科技主要代理的产品有小华半导体MCU,英飞凌工业XMC系列MCU,Qorvo智能电机控制SOC芯片,上海贝岭的LDO,eeprom,高压MOS，IGBT,电机栅极驱动器以及其他功能各种封装芯片。'
              }
            }
          }
        ]
      }
    ]
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {

  // 路由发生变化改变description和keyword
  
  if (to.meta.content) {
  
  const head = document.getElementsByTagName('head')
  
  const meta = document.createElement('meta')
  
  document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
  
  document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
  
  meta.content = to.meta.content
  
  head[0].appendChild(meta)
  
  }
  
  // 路由发生变化修改页面title
  
  if (to.meta.title) {
  
  document.title = to.meta.title
  
  }
  
  next()
})
export default router
